import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';

import theme from '../utils/themeconstants';

const CommentExpand = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  h4{
    text-align: left;
  }
`;

const CommentBox = styled.div`
  overflow: hidden;
  margin-bottom: ${theme.space.lg}px;
  height: ${props => (props.commentsExpanded ? 'auto' : '0px')};
`;

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsExpanded: false,
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://commento.tmrow.com/js/commento.js');
    script.setAttribute('async', '');
    document.getElementById('commentoScript').appendChild(script);
  }

  toggleComments = () => {
    this.setState(prevState => ({
      commentsExpanded: !prevState.commentsExpanded,
    }));
  }

  render() {
    return (
      <>
        <CommentExpand
          type="button"
          onClick={this.toggleComments}
        >
          <h4>
            Comments
            {' '}
            <FontAwesomeIcon
              icon={this.state.commentsExpanded ? faMinus : faPlus}
              size="sm"
            />
          </h4>
        </CommentExpand>
        <CommentBox id="commentbox" commentsExpanded={this.state.commentsExpanded}>
          <div id="commento" />
          <div id="commentoScript" />
        </CommentBox>
      </>
    );
  }
}

export default Comments;
