import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { csv } from 'd3-fetch';
import { sum } from 'd3-array';
import styled from 'styled-components';

import Link from '../elements/link';
import { initialOption, parseGiss, initialDataZoom } from './common';

const Graph = styled.div`
   width: 100%;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
`;

const Source = styled.div`
  font-size: small;
`;

const GraphTitle = styled.div`
  font-weight: bold;
`;

const GraphDescription = styled.div`
`;

const WorldPrimaryEnergy = () => {
  const [option, updateOption] = useState(initialOption());

  const loadData = () => {
    csv('/data/global-primary-energy-consumption-1800-2015.csv').then((obj) => {
      // Add an aggreate field that sums biofuels
      obj.forEach((d) => {
        d.Biofuels = +d['Modern biofuels'] + +d['Traditional biofuels'];
      });
      const mapping = {
        Coal: 'coal',
        'Crude oil': 'oil',
        'Natural gas': 'gas',
        Hydroelectricity: 'hydro',
        'Nuclear electricity': 'nuclear',
        'Wind and solar': 'wind & solar',
        // 'Modern biofuels': 'biomass',
        // 'Traditional biofuels': 'biomass'
        Biofuels: 'biomass',
      };
      const colors = {
        'wind & solar': '#74cdb9',
        // 'solar': '#f27406',
        hydro: '#2772b2',
        biomass: '#166a57',
        nuclear: '#AEB800',
        gas: '#bb2f51',
        coal: '#ac8c35',
        oil: '#867d66',
      };
      const byFuel = {};
      const xData = [];
      obj.forEach((d) => {
        xData.push(d.Year);
        Object.keys(d).forEach((k) => {
          if (!mapping[k]) { return; }
          const kk = mapping[k];
          if (!byFuel[kk]) { byFuel[kk] = []; }
          byFuel[kk].push(+d[k]);
        });
      });

      const data = [
        { name: 'biomass', values: byFuel.biomass },
        { name: 'coal', values: byFuel.coal },
        { name: 'oil', values: byFuel.oil },
        { name: 'gas', values: byFuel.gas },
        { name: 'hydro', values: byFuel.hydro },
        { name: 'nuclear', values: byFuel.nuclear },
        { name: 'wind & solar', values: byFuel['wind & solar'] },
      ];

      const newOption = initialOption();

      newOption.xAxis.data = xData;

      newOption.tooltip.formatter = (e) => {
        e.reverse();
        const year = e[0].axisValueLabel;
        const sumdata = sum(e, d => d.data);
        let html = `${year}:<br />${
          e.map(d => `${d.marker} ${d.seriesName}: ${d.data} (${Math.round(d.data * 100 / sumdata)} %)`).join('<br />')}`;
        html += '<br />--<br />';
        const ff = sum(e.filter(d => ['gas', 'oil', 'coal'].indexOf(d.seriesName) !== -1), d => d.data);
        html += `Fossil fuels: ${Math.round(ff * 100) / 100} (${Math.round(ff * 100 / sumdata)} %)`;
        return html;
      };

      newOption.legend = {
        data: data.map(d => d.name).reverse(),
        orient: 'vertical',
        x: 'right',
        y: 'center',
      };
      newOption.legend.selected = {};
      data.forEach((d) => {
        newOption.legend.selected[d.name] = true;
      });

      newOption.grid.right = 120;

      newOption.yAxis.max = 550;

      newOption.color = data.map(d => colors[d.name]);
      newOption.series = data.map(d => ({
        name: d.name,
        type: 'line',
        symbol: 'none',
        stack: 'common',
        data: d.values,
        areaStyle: { normal: { } },
        lineStyle: { normal: { } },
      }));

      updateOption(newOption);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Graph>
      <GraphTitle>World primary energy consumption since 1800</GraphTitle>
      <GraphDescription>in EJ (exajoules)</GraphDescription>
      <ReactEcharts
        option={option}
        notMerge
      />
      <Source>
        Source:
        {' '}
        <Link outward href="https://ourworldindata.org/energy-production-and-changing-energy-sources/#global-total-energy-production-long-run-view-by-source">Our World In Data</Link>
        {' '}(
        <Link outward href="https://ourworldindata.org/grapher/global-primary-energy-consumption-1800-2015.csv">data</Link>
        )
      </Source>
    </Graph>
  );
};

export default WorldPrimaryEnergy;
