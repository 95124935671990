import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { text } from 'd3-fetch';
import styled from 'styled-components';

import Link from '../elements/link';
import { initialOption, parseGiss, initialDataZoom } from './common';

const Graph = styled.div`
   width: 100%;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
`;

const Source = styled.div`
  font-size: small;
`;

const GraphTitle = styled.div`
  font-weight: bold;
`;

const GraphDescription = styled.div`
`;

const Food = () => {
  const [option, updateOption] = useState(initialOption());

  const loadData = () => {
    const modeColor = {
      vegetables: '#166a57',
      fruits: 'green',
      bread: '#f27406',
      milk: 'darkgray',
      chicken: '#ac8c35',
      cattle: 'darkred',
    };

    const data = [
      { name: 'vegetables', intensity: 2.8, label: '🥕 vegetables' },
      { name: 'fruits', intensity: 4.6, label: '🍏 fruits' },
      { name: 'bread', intensity: 1.3, label: '🍞 bread & cereals' },
      { name: 'milk', intensity: 4.5, label: '🥛 milk' },
      { name: 'chicken', intensity: 3.8, label: '🐔 chicken, 🐟 fish & 🐷 pork' },
      { name: 'cattle', intensity: 14.1, label: '🐮 beef & lamb' },
    ].reverse();

    const newOption = initialOption();

    newOption.grid.left = 160;

    newOption.xAxis.name = 'Greenhouse gas intensity in gCO₂eq per kcal';
    newOption.xAxis.nameLocation = 'middle';
    newOption.xAxis.nameGap = 30;
    newOption.grid.bottom = 50;

    newOption.xAxis.type = 'value';
    newOption.yAxis.data = data.map(d => d.label || d.name);

    newOption.tooltip.trigger = 'none';

    newOption.series = [
      {
        type: 'bar',
        data: echarts.util.map(data, d => ({
          value: d.intensity,
          itemStyle: {
            normal: {
              color: modeColor[d.name],
            },
          },
        })),
        label: {
          normal: {
            show: true,
            position: 'right',
          },
        },
      },
    ];
    updateOption(newOption);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Graph>
      <GraphTitle>Greenhouse gas intensity of food</GraphTitle>
      <GraphDescription>
        in gCO
        <sub>2</sub>
        eq / kcal
      </GraphDescription>
      <ReactEcharts
        option={option}
        notMerge
      />
      <Source>
        Source: Carbon Intensity of Eating, from
        {' '}
        <Link outward href="http://shrinkthatfootprint.com/food-carbon-footprint-diet">Shrink that footprint</Link>
        .
      </Source>
    </Graph>
  );
};

export default Food;
